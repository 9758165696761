import React from "react";
import Accordion from "./Accordion";
import axios from "axios";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { transactions: [], personalTotal: [] };
  }

  componentDidMount() {
    axios
      .get("https://split5722.yupeicao.com/transaction/minimalTransaction")
      .then((res) => {
        this.setState({
          transactions: res.data.reducedTransactions,
          personalTotal: res.data.personalTotal,
        });
      });
  }
  render() {
    let balance = this.state.personalTotal;
    balance.sort((a, b) => {
      return a.balance - b.balance;
    });

    return (
      <Accordion heading="Overview" active={true}>
        <div className="flex flex-col px-5">
          <h3 className="font-medium">Personal Balance</h3>
          {balance.map((val, idx) => {
            return (
              <div className="flex justify-between text-sm" key={idx}>
                <span className="my-0.5">{val.user}:</span>
                <span className="my-0.5">
                  {val.balance < 0 ? "-" : "+"}$
                  {Math.abs(Math.round(val.balance * 100) / 100)}
                </span>
              </div>
            );
          })}
          <hr className="my-2 border-gray-400 border-1 min-w-full" />
          <h3 className="font-medium">Transactions to clear debts:</h3>
          {this.state.transactions.map((val, idx) => {
            return (
              <div key={idx} className="flex justify-between text-sm">
                <span className="my-0.5">{`${val.consumer} to ${val.payer}:`}</span>
                <span className="my-0.5">
                  ${Math.round(val.amount * 100) / 100}
                </span>
              </div>
            );
          })}
        </div>
      </Accordion>
    );
  }
}

export default Overview;
