import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import Overview from "./components/Overview";
import TransactionForm from "./components/TransactionForm";
import TransactionTable from "./components/TransactionTable";
import SmallTable from "./components/SmallTable";

const App = () => {
  return (
    <div className="App m-0 p-0 h-full max-w-full box-border flex-col">
      <div className="m-0 pt-1 pb-2 max-w-full bg-gray-200 text-center">
        <h1 className="m-0 text-xl font-extrabold text-center max-w-full inline px-2">
          Split5722
        </h1>
      </div>
      <div className="flex">
        <div className="flex-shrink flex-grow px-2 max-w-full box-border sm:max-w-md lg:max-w-lg">
          <div className="pt-1 sticky top-0">
            <Overview />
            <TransactionForm />
            <SmallTable />
          </div>
        </div>
        <div className="hidden flex-grow sm:block pt-2">
          <TransactionTable />
        </div>
      </div>
    </div>
  );
};

ReactDOM.render(<App />, document.querySelector("#root"));
