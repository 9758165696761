import axios from "axios";
import React from "react";
import Accordion from "./Accordion";
import NameCard from "./NameCard";
const MY_PARTY = ["CRY", "CYP", "QYZ"];
class TransactionForm extends React.Component {
  constructor(props) {
    super(props);
    const consumers = new Map();
    const currentParty = MY_PARTY;
    currentParty.forEach((val) => {
      consumers.set(val, false);
    });
    this.state = { consumers: consumers, payer: null };

    this.onConsumerClick = this.onConsumerClick.bind(this);
    this.onPayerClick = this.onPayerClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onConsumerClick(e) {
    const name = e.target.innerText;
    const newConsumers = new Map();
    this.state.consumers.forEach((val, key) => {
      newConsumers.set(key, val);
    });
    newConsumers.set(name, !newConsumers.get(name));
    this.setState({ consumers: newConsumers });
  }

  onPayerClick(e) {
    const name = e.target.innerText;
    this.setState({ payer: name });
  }

  onSubmit() {
    const consumers = Array.from(this.state.consumers)
      .filter((val) => {
        return val[1];
      })
      .map((val) => {
        return val[0];
      });
    const amount = document.getElementById("amount").value;
    const note = document.getElementById("note").value;
    const payer = this.state.payer;

    if (amount <= 0) {
      console.error("amount <= 0");
      return;
    }
    if (consumers.length === 0) {
      console.error("no consumers");
      return;
    }

    if (payer === null) {
      console.error("no payer");
      return;
    }

    if (note === "") {
      console.error("no note");
      return;
    }

    if (consumers.length === 1 && payer === consumers[0]) {
      console.error("payer and consumer are the same person");
      return;
    }

    const payload = { consumers, payer, amount, note };
    axios
      .post("https://split5722.yupeicao.com/transaction", payload)
      .then((res) => {
        document.getElementById("amount").value = "";
        document.getElementById("note").value = "";
        const newConsumers = new Map();
        const currentParty = MY_PARTY;
        currentParty.forEach((val) => {
          newConsumers.set(val, false);
        });
        this.setState({ consumers: newConsumers, payer: null });
      });
  }

  render() {
    const consumerCards = [];
    const payerCards = [];
    this.state.consumers.forEach((val, key) => {
      consumerCards.push(
        <NameCard
          username={key}
          active={val}
          key={key}
          onClick={this.onConsumerClick}
        />
      );
    });

    this.state.consumers.forEach((val, key) => {
      payerCards.push(
        <NameCard
          username={key}
          active={key === this.state.payer}
          key={key}
          onClick={this.onPayerClick}
        />
      );
    });

    return (
      <Accordion heading="New Transaction" active={true}>
        <div className="grid grid-rows-2 grid-cols-3 mt-2 px-5">
          <label htmlFor="amount" className="pr-2 font-medium col-span-1">
            Amount
          </label>
          <div className="relative rounded-md shadow-sm col-span-2">
            <div className="absolute inset-y-0 left-0 pl-1">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              name="amount"
              id="amount"
              placeholder="0.00"
              min="0.00"
              className="bg-gray-200 focus:bg-white text-sm pl-4 border-gray-300 rounded-md placeholder-blueGray-300 placeholder-opacity-75 w-full"
            />
          </div>

          <label htmlFor="note" className="font-medium pr-2 col-span-1">
            Note
          </label>
          <textarea
            type="text"
            name="note"
            id="note"
            placeholder="Bubble Tea"
            className="rounded-md shadow-sm bg-gray-200 focus:bg-white text-sm pl-2 border-gray-300 placeholder-blueGray-300 placeholder-opacity-75 col-span-2"
          />
        </div>
        <div className="flex flex-col items-center my-2">
          <label className="font-medium pr-2 col-span-1 text-center">
            Select Consumer
          </label>
          <div className="flex justify-around w-full">{consumerCards}</div>
        </div>

        <div className="flex flex-col items-center my-2">
          <label className="font-medium pr-2 col-span-1 text-center">
            Select Payer
          </label>
          <div className="flex justify-around w-full">{payerCards}</div>
        </div>
        <div className="flex justify-center">
          <button
            className="block w-3/5 font-medium my-2 border-2 rounded-md hover:bg-gray-800 hover:text-white"
            onClick={this.onSubmit}
          >
            Submit
          </button>
        </div>
      </Accordion>
    );
  }
}

export default TransactionForm;
