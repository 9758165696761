import React from "react";
import Accordion from "./Accordion";
import TransactionTable from "./TransactionTable";

export default class SmallTable extends React.Component {
  render() {
    return (
      <div className="sm:hidden">
        <Accordion heading="Transactions" active={false}>
          <div className="flex">
            <TransactionTable />
          </div>
        </Accordion>
      </div>
    );
  }
}
