import React from "react";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: this.props.active };
  }

  render() {
    return (
      <div className="rounded border-solid border-gray-200 border-2 mt-2 max-w-full">
        <button
          className="bg-[#f7f8fa] cursor-pointer w-full border-none outline-none px-2 py-1 flex items-center"
          onClick={() => {
            this.setState({
              active: !this.state.active,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
          <h2 className="text-left px-2 font-semibold text-lg">
            {this.props.heading}
          </h2>
        </button>
        <div
          className="overflow-x-hidden transition-all 
          duration-200 ease-[cubic-bezier(.7,.16,.19,.95)]"
          style={{ maxHeight: this.state.active ? "100vh" : "0" }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Accordion;
