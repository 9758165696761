import React from "react";

export default class NameCard extends React.Component {
  render() {
    return (
      <button
        className={
          (this.props.active ? " border-black" : "") +
          " " +
          "border-4 py-3  px-5 font-medium rounded-lg " +
          "md:hover:shadow-xl md:transform md:hover:scale-105 md:transition md:duration-100"
        }
        onClick={this.props.onClick}
        id={this.props.id}
      >
        {this.props.username}
      </button>
    );
  }
}
