import React from "react";
import axios from "axios";

class TransactionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { transactions: [] };
  }

  componentDidMount() {
    axios.get("https://split5722.yupeicao.com/transaction").then((res) => {
      this.setState({ transactions: res.data });
    });
  }

  render() {
    return (
      <table className="w-full flex-shrink divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="text-center sm:text-right">Date</th>
            <th className="text-center sm:text-right">Amount</th>
            <th className="text-center sm:text-right">Party</th>
            <th className="">Payer</th>
            <th className="hidden sm:table-cell">Note</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {this.state.transactions.map((val, idx) => {
            return (
              <tr key={val._id}>
                <td className="text-right">
                  {new Date(val.date).toLocaleString("en-US", {
                    year: "2-digit",
                    month: "numeric",
                    day: "2-digit",
                    timeZone: "US/Eastern",
                  })}
                </td>
                <td className="text-right">{val.amount.toFixed(2)}</td>
                <td className="text-right">{val.party.join(" ")}</td>
                <td>{val.payer}</td>
                <td className="hidden sm:table-cell">{val.note}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
export default TransactionTable;
